import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'ssb-personal-info-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'ssb-personal-info';
  @ViewChild('iframe') iframe: ElementRef;
  constructor() {}
  ngOnInit() {
  }
  public loadCSS(){
    console.log(this.iframe.nativeElement.contentWindow.document);
    const event = new CustomEvent('loadSSBPersonalInfo');
    document.dispatchEvent(event);
  }
}
